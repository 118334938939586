html,
body,
#root,
.app {
    height: 100%;
}

body {
    font-family: var(--font-primary);
    font-size: var(--font-size);
    background-color: var(--color-primary);
    color: var(--color-primary-text);

    margin: 0;
    font-display: block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    text-transform: capitalize;
}

h1.tertiary-text {
    font-size: 2em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-secondary);
    font-weight: 400;
}

a {
    cursor: pointer;
    text-decoration: inherit;
    color: inherit;
    outline-color: var(--color-action-button);
}

@media (max-width: 64em) {
    h1 {
        font-size: 1.5em
    }

    h2 {
        font-size: 1.125em
    }

    h3 {
        font-size: 0.975em
    }

    h4 {
        font-size: 0.75em
    }

    h5 {
        font-size: 0.6em
    }

    h6 {
        font-size: 0.525em
    }
}

header {
    font-family: var(--font-secondary);
    display: block;
    text-transform: uppercase;
    background-color: var(--color-menu);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    font-size: 1em;
    z-index: 1000;
    height: var(--height-header);
    line-height: var(--height-header);
}

header .header {
    max-width: 80em;
    /* 1280px */
    margin-left: auto;
    margin-right: auto;
}

main {
    margin-top: var(--height-header);
    margin-bottom: 0.5em;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    box-sizing: border-box;
    height: 2.4375rem;
    padding: 0.5rem;
    border: 0.15em solid var(--color-action-active);
    border-radius: 0.25em;
    font-family: Comfortaa, sans-serif;
    font-size: 1em;
    font-weight: 600;
    overflow: visible;

    background-color: var(--color-primary);
    color: var(--color-primary-text);
}

input[type="text"]:focus-visible,
input[type="email"]:focus-visible,
input[type="password"]:focus-visible {
    outline: 0px;
}

input[type="submit"] {
    cursor: pointer;
}

.page {
    margin-left: auto;
    margin-right: auto;
    max-width: 80em;
    box-sizing: border-box;
    padding-left: 1px;
    padding-right: 1px;
}

.blur {
    text-shadow: 0 0 10px rgba(var(--color-primary-text-rgb), 0.5);
    color: transparent !important;
}

.blur.action {
    text-shadow: 0 0 10px rgba(var(--color-action-text-rgb), 0.5);
}

.grecaptcha-badge {
    visibility: hidden;
}

#login .grecaptcha-badge {
    visibility: visible;
    position: fixed;
    bottom: 1em;
    right: 1em;
}

@media (max-width: 25em) {
    #login .grecaptcha-badge {
        visibility: visible;
        margin-left: auto;
        margin-right: auto;
        right: 1em;
        left: 1em;
        z-index: 5000;
    }
}

footer {
    color: var(--color-secondary-text);
    background-color: var(--color-secondary);
    padding: 1em;
}

footer a:hover {
    color: var(--color-action-button);
    text-decoration: underline;
}

#logo-image {
    margin-left: 4px;
    transform: translateY(-6px);
}

.logo a {
    text-decoration: none;
}

.action {
    cursor: pointer;
    text-transform: uppercase;
    color: var(--color-action-text);
    background-color: var(--color-action);
}

.badge {
    color: var(--color-action-text);
    background-color: var(--color-action-active);
}

.button-action {
    color: var(--color-action-text);
    background-color: var(--color-action-button);
}

.button {
    font-family: var(--font-secondary);
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.button a {
    display: block;
    text-decoration: none;
}

.primary {
    background-color: var(--color-action);
}

.primary-text,
.primary-text a {
    color: var(--color-primary);
}

.secondary {
    background-color: var(--color-action-button);
}

.secondary-text,
.secondary-text a {
    color: var(--color-action-active);
    text-decoration: none;
}

.tertiary {
    background-color: var(--color-tertiary);
}

.tertiary-text {
    color: var(--color-tertiary-text);
}

.border {
    border: var(--size-border, 1px) solid var(--color-border);
}

.break {
    /* From: https://stackoverflow.com/a/33214667 */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
}

.card {
    background-color: var(--color-card);
    color: var(--color-card-text);
    padding: var(--padding-card);
    margin: var(--margin-card);
}

.heading {
    text-transform: uppercase;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 1s;
}

.fade-exit-done {
    opacity: 0;
}